*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Rubik', 'sans-serif';
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}
@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

.homecard{
  height: 120px;
  border-radius: 20px;
  padding: 0;
  display: flex;
  margin-top: 15px;
  cursor: pointer;
}

.cursor-pointer{
  cursor: pointer;
}

.angledown{
  transition: transform 1s linear;
}

.info-tablex td{
  vertical-align: middle;
}

.small-ball{
  animation: light 1s forwards infinite alternate;
}

.rdrDateRangePickerWrapper{
  border: 1px solid #80808047;
}

@keyframes light {
  0%{
    opacity: 100%;
  }

  100%{
    opacity: 40%;
  }
}

.card-divx{
width: 65%;
}

.card-imgx{
width: 35%;
}

.connected-div{
  width: 47% !important;
}

@media only screen and (max-width: 500px){
  .status-upper-div{
    width: 100% !important;
  }
}

.editor-main-text p{
  margin: 0;
}

.custom-comment{
  padding: 1px;
  background-color: transparent;
  border: 0;
  border-bottom: 0.5px solid;
}

.custom-comment:focus{
  background: transparent;
  border: 0;
  border-bottom: 0.5px solid;
}